body.dark-mode {
  background-color: #000;
  color: #fff;
}

.App {
  padding: 1rem;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.App.dark-mode {
  background-color: #000;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.icon {
  width: 50px;
  height: 50px;
  margin-right: 8px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.App.dark-mode li {
  background-color: #333;
  color: #fff;
}

.article-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

h2, p, a {
  margin: 0;
  padding: 0;
}

.App.dark-mode h2,
.App.dark-mode p,
.App.dark-mode a {
  color: #fff;
}

.articles li .bullet-summary {
  white-space: pre-line;
}

.App.dark-mode .articles li .bullet-summary {
  color: #fff;
}

.dark-mode-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
}
.dark-mode .icon {
  filter: invert(1);
}


.agency-icon {
  cursor: pointer;
  width: 25px; /* adjust as needed */
  height: 25px; /* adjust as needed */
}

.agency-icon.active {
  /* your active styling here */
}
.all-button {
  border: none;
  padding: 0;
  background-color: transparent;
}
